html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    Hiragino Kaku Gothic ProN, "\30D2\30E9\30AE\30CE\89D2\30B4   ProN W3", Arial, "\30E1\30A4\30EA\30AA", Meiryo,
    sans-serif;
  overflow-x: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

@media print {
  button {
    display: none !important;
  }
}

svg.recharts-surface {
  overflow: overlay;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall {
  /*min-width: 240px;*/
}

.MuiTableRow-root.MuiTableRow-hover {
  white-space: break-spaces;
}

.MuiFormLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}
.MuiFormLabel-asterisk.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollBody.MuiDialog-paperWidthSm {
  min-width: 600px;
  max-width: 80vw !important;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollBody.MuiDialog-paperWidthSm
  .MuiDialogContent-root {
  overflow-y: unset;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollBody.MuiDialog-paperWidthSm
  .MuiGrid-root.MuiGrid-item {
  padding-top: 6px;
  padding-bottom: 6px;
}

table td.MuiTableCell-sizeSmall {
  padding: 0px 24px 0px 16px;
}

.filterdialog {
  overflow-y: scroll;
}

.filterdialog .MuiDialog-container.MuiDialog-scrollBody {
  height: unset;
  min-height: 1280px;
}

/* テーブル構造変更 */
.tableWrap {
  overflow-x: auto;
  transform: rotateX(180deg);
}

.tableWrap table {
  width: 100%;
  min-width: 1020;
  overflow: scroll;
  border-collapse: unset;
  border-spacing: 0 1px;
}
.tableWrap table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  background-color: lightblue;
  transform: rotateX(180deg);
  top: 100%;
}
.tableWrap table tbody {
  transform: rotateX(180deg);
  position: -webkit-sticky;
  position: sticky;
  bottom: 39px;
}

.tableWrap.columFourTable table th:nth-child(4),
.tableWrap.columFourTable table td:nth-child(4) {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  left: 0;
}
.tableWrap.columFourTable table th:nth-child(4) {
  background-color: lightblue;
}

.tableWrap.columFourTable table td:nth-child(4) {
  background-color: #f5f9f9;
}

.tableWrap.columThreeTable table th:nth-child(3),
.tableWrap.columThreeTable table td:nth-child(3) {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  left: 0;
}
.tableWrap.columThreeTable table th:nth-child(3) {
  background-color: lightblue;
}

.tableWrap.columThreeTable table td:nth-child(3) {
  background-color: #f5f9f9;
}

.tableWrap.columTwoTable table th:nth-child(2),
.tableWrap.columTwoTable table td:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  left: 0;
}
.tableWrap.columTwoTable table th:nth-child(2) {
  background-color: lightblue;
}

.tableWrap.columTwoTable table td:nth-child(2) {
  background-color: #f5f9f9;
}

.hikaku .MuiInputBase-input.MuiInput-input > div {
  flex-wrap: nowrap;
}
.hikaku .MuiInputBase-input.MuiInput-input > div > div {
  padding: 1px;
}
.hikaku .MuiInputBase-input.MuiInput-input > div > p.MuiTypography-root {
  white-space: nowrap;
  font-size: 0.8rem;
}

